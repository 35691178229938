@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.ttf');
  font-weight: 900;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  font-family: Montserrat;
  font-style: normal;
}

.ant-slider-track {
  background-color: #000000;
}

.ant-slider:hover .ant-slider-track {
  background-color: #000000;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #C4C4C4;
}
.ant-slider-handle:focus {
  border-color: #C4C4C4;
  -webkit-box-shadow: 0 0 0 5px #ffffff00;
  box-shadow: 0 0 0 5px #ffffff00;
}

.ant-slider-handle {
  border: solid 2px #C4C4C4;
  background: #C4C4C4;
  border-radius: 3px;
}

.ant-checkbox-inner {
  background-color: #C4C4C4;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C4C4C4;
  border-color: #C4C4C4;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #C4C4C4;
}

.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}

.ant-radio-inner {
  background-color: #C4C4C4;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #C4C4C4;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #C4C4C4;
}

.ant-radio-inner::after {
  background-color: #898989;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 22px 22px;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 100%;
}

.ant-layout {
  background: #F5F5F5;
}

.ant-layout-sider-children {
  background: #F5F5F5;
}

.margin-left {
  margin-left: 5%;
}

.background-white {
  background-color: #FFFFFF;
}

.footer-wrapper {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
  z-index: 1;
  width: 100%;
  margin-top: 20px;
  bottom: 0px;
  padding: 24px 96px 0px;
}

.header-wrapper {
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  text-align: center;
  overflow: hidden;
  padding: 0px 96px;
  z-index: 1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.sidebar-collapse {
  padding: 0px;
  font-size: 18px;
  img {
    margin-left: 5px;
  }
  .inverted {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.compare-bar-wrapper {
  background: #FFFFFF;
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 2;
  padding-left: 96px;
  padding-right: 96px;
  width: 100%;
  margin-left: -20px;
  margin-right: -96px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.25);
}

@media (max-width: 992px){
  .compare-bar-wrapper{
    display: none;
  }
}

@media (max-width: 600px){
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 8px;
  }
  .header-wrapper {
    padding: 0 20px;
  }
}

.capitalize {
  text-transform: capitalize;
}
