.ant-modal-content{
  background-color: rgba(0, 0, 0, 0) !important;
  .login-form{
    .ant-input{
      border-radius: 2px;
    }
    .content{
      overflow-y: hidden;
      padding: 100px 58px 38px;
    }
    .remember{
      color: #FFFF;
      border-style: none;
    }
    .login-form-forgot{
      color: #FFFF;
    }
    .login-button{
      width: 100%;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      font-variant: small-caps;
      background: rgba(0, 0, 0, 0.3);
      border-style: none;
      border-radius: 2px;
    }
    .register-now{
      color: #FFFF;
      margin-top: 10px;
      p{
        margin-right: 5px;
      }
      a{
        color: #FFFF;
        font-weight: bold;
        text-decoration-line: underline;
      }
    }
  }

  .register-form{
    .ant-input{
      border-radius: 2px;
    }
    .birhtday-date{
      width: 100%;
    }
    .foam{
      width: 100%;
    }
    .register-content{
      padding: 100px 58px 38px;
      border-radius: 15px 15px 5px 5px;
      border: 0px;
    }
    .gender-btn{
      width: 150px;
      text-align: center;
      border-style: none;
    }
    .register-form-forgot{
      color: #FFFF;
    }
    .terms-of-use{
      color: #FFFF;
      a{
        color: #FFFF;
        font-weight: bold;
        text-decoration-line: underline;
      }
    }
    .register-button{
      width: 100%;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      font-variant: small-caps;
      background: rgba(0, 0, 0, 0.3);
      border-style: none;
      border-radius: 2px;
    }
    .register-now{
      color: #FFFF;
      margin-top: 10px;
      p{
        margin-right: 5px;
      }
      a{
        color: #FFFF;
        font-weight: bold;
        text-decoration-line: underline;
      }
    }
  }
}
