.checkout-wrapper {
  margin-top: 2%;
}

.billing-form {
  padding: 3%;
  .ant-form-item-extra {
    color: #ff4d4f;
  }
  .group-title {
    margin-top: 0;
  }
}

.form-item {
  margin-bottom: 24px;
}

.checkout-options {
  display: block;
  height: 30px;
  line-height: 30px;
}

.text-right {
  text-align: right;
}
