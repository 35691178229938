.ant-select-show-arrow {
  width: 100%;
}

.ant-select-dropdown {
  padding: 0px;
}

.ant-select-arrow {
  margin-right: 25px;
}

.ant-select-item-option-content {
  font-family: 'Montserrat';
  font-size: 20px;
}

.ant-select-item-option {
  height: 50px;
  padding: 3%;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
  background: #E7E7E7;
}

.compare-item-wrapper {
  width: 100%;
  margin: 0px auto;
  height: 100%;
  .dpdown{
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 3px;
    height: 55px;
    margin-bottom: 20px;
    .row {
      height: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .item-title{
      text-align: left;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      .ant-select-selector {
        width: 90%;
        span:nth-child(2) {
          font-size: 20px;
        }
      }
    }
    .dropd-btn-wrapper{
      text-align: right;
    }
  }
  .card{
    text-align: center;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3px;
    padding-top: 50px;
    .buy-btn-wrapper{
      margin-right: 3%;
      margin-bottom: 5%;
    }
    .card-img-wrapper {
      margin: auto;
      margin-bottom: 5%;
      width: 100%;
      height: auto;
      .card-img {
        max-width: 250px;
        width: 100%;
      }
    }
    .props-wrapper{
      border: 1px solid #E7E7E7;
      box-sizing: border-box;
      border-radius: 0px 0px 3px 3px;
      height: 100%;
      .item-rows {
        height: 80px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
        }
        .attribute-title{
          font-weight: 300;
        }
        .attribute-value{
          font-weight: 400;
        }
      }
    }
  }
}

.grey {
  background-color: #F9F9F9;
}
