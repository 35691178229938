.homepage-background {
  background-image: url("../images/hero.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 10%;
  height: 750px;
}

.button-row {
  margin-top: 25%;
}

.sections-wrapper {
  padding-left: 5%;
  padding-right: 5%;
  height: 750px;
}

.title {
  font-weight: 600;
  font-size: 68px;
  margin-bottom: 0px;
}

.sub-title {
  font-weight: 600;
  font-size: 42px;
  margin-bottom: 10%;
}

.middle-bar {
  background-color: #EFEFEF;
  height: 90px;
  .middle-bar-text {
    font-weight: 600;
    font-size: 36px;
  }
}

.image {
  background-image: url("../images/body.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 369px;
}

.suggestions-title {
  font-size: 30px;
  font-weight: 600;
}

.suggestions {
  margin-top: 5%;
}
