.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  cursor: default;
  padding: 0px;
}

.item-header {
  padding: 20px 20px;
}

@media (max-width: 600px){
  .card-wrapper .card-content {
    .item-title-wrapper{
      margin-top: 10px !important;
      margin: auto;
      p{
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .item-header {
    padding: 12px 16px;
  }
  .card-wrapper .card-content{
    padding-left: 0px !important;
  }
  .divider{
    display: none;
  }
  .card-buttons-wrapper{
    display: flex;
    .ant-row-end {
      justify-content: center;
    }
    .hidden {
      display: none;
    }
    .rows{
      width: 100%;
      max-width: 33%;
      margin: auto;
    }
    .card-button{
      width: auto;
      height: 30px;
      font-size: 14px !important;
      line-height: 9px !important;
      text-align: center;
    }
  }
}

.card-buttons-wrapper {
  margin: auto;
  width: 100%;
  text-align: center;
  .rows {
    margin-top: 5px;
  }
  .card-button {
    width: 100%;
    max-width: 94px;
    padding: 0;
    border-radius: 3px;
    font-family: Montserrat;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    background-color: #FEB300;
    span {
      width: 60%;
    }
  }
  .more-info {
    span {
      width: 100%;
    }
  }
}

.price-value {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  white-space: nowrap;
}

.divider {
  height: 70px;
  border-left: 0.5px solid rgba(0, 0, 0, 0.2);
  margin: 0px;
}

.card-wrapper-selected {
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.25), 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.card-wrapper{
  background-color: #FFFEFE;
  box-sizing: border-box;
  border-radius: 5px;
  .card-header-wrapper {
    margin-right: -70px;
  }

  .img-wrapper {
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      max-width: 150px;
      max-height: 150px;
    }
  }

  .info-btn-label{
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 34px;
    z-index: 2
  }

  @media (max-width: 450px){
    .info-btn-label{
      bottom: 2px;
      right: 19px;
    }
  }

  .card-content {
    padding-left: 24px;
    .item-title {
      font-size: 24px;
      font-weight: 500;
      margin-right: 10px;
      display: inline-block
    }

    .item-subtitle {
      font-size: 24px;
      font-weight: 400;
      display: inline-block
    }

    .add-to-compare{
      text-align: center;
    }

    .item-props{
      margin-right: 15px;
      height: 70px;
      max-width: 146px;
      .props-title {
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }
      .props-text {
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    @media (max-width: 600px){
      .info-btn-label{
        position: absolute;
        bottom: 2px;
        right: 19px;
        z-index: 2
      }
      .capitalize{
        margin: auto;
      }
      .item-props{
        display: flex;
        margin-right: 0px;
        height: 50px;
        min-width: 100%;
        .props-title{
          display: block;
          flex: auto;
        }
      }
      .item-props-wrapper {
        .item-props:last-of-type {
          .props-title{
            font-weight: 500;
          }
          .props-text {
            font-weight: 500;
          }
        }
      }
    }
  }
}
