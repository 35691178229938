.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.group-title {
  margin-top: 5%;
  h1.ant-typography, .ant-typography h1 {
    font-weight: 600;
    font-size: 42px;
    position: relative;
  }
}

.group-subtitle {
  margin-top: 3%;
  h1.ant-typography, .ant-typography h1 {
    font-weight: 400;
    font-size: 26px;
    position: relative;
  }
}

.quest-background {
  background-color: #F9F9F9;
  border: 2px solid #B5B5B5;
}

.quest-wrapper {
  text-align: center;
  margin-top: 2%;

  .quest-options {
    display: block;
    height: 40px;
    line-height: 30px;
    background-color: #E5E5E5;
    width: 400px;
    margin: auto;
    margin-bottom: 1%;
    border-radius: 3px;
  }

  .quest-submit-button-wrapper{
    margin-bottom: 0%;
  }

  .quest-submit-button {
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    color: #000;
    background-color: #FEB300;
    border-radius: 3px;
  }

  .quest-title {
    font-size: 26px;
    margin-bottom: 10%;
    font-weight: 600;
  }

  .quest-description {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20%;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #9E9E9E;
    background: #9E9E9E;
    border: 1px solid #E7E7E7;
    span {
      color: #000000;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #FFFFFF;
  }

  .ant-radio-button-wrapper:hover {
    color: #000000;
    border: 1px solid #E7E7E7;
  }

  .center {
    text-align: center;
  }

  .form-wrapper {
    padding: 5%;
    width: 100%;
    .ant-collapse-content {
      padding-top: 5%;
      padding-bottom: 2%;
    }
  }

  .form {
    .ant-collapse-item {
      .ant-collapse-header {
        text-align: left;
        padding-left: 20px;
        .title {
          font-size: 42px;
          font-weight: 600;
        }
        .edit-label-wrapper {
          margin: auto;
          .edit-label {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 0px;
            text-align: right;
            padding-right: 20px;
          }
        }
        .question-preview {
          color: #b1b1b1;
        }
        .answer-preview {
          color: #000000;
          margin-left: 20px;
        }
      }
    }
    width: 100%;
    margin-bottom: 2%;
    border-radius: 3px;
  }

  h4.ant-typography, .ant-typography h4 .form-title {
    margin-bottom: 20%;
  }

  .form-question-wrapper {
    margin-bottom: 70%;
  }

  .form-options {
    width: 100%
  }

  .ant-form-item {
    margin-bottom: 10%;
    margin-top: 3%;
  }

  .ant-form-item:nth-last-child(1) {
    margin-top: -7%;
    margin-bottom: 1%;
  }

  .form-submit {
    background-color: #FEB300;
    background: #FEB300;
    border-color: #FEB300;
    color: #000000;
  }
}
