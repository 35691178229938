.inverted {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.back-button {
  margin-top: 30px;
}

.items-wrapper{
  height: 90%;
  margin-top: 60px;
  .card-wrapper {
    height: 100%;
    background-color: #FFFEFE00;
  }
  .attributes-wrapper {
    height: 100%;
    padding-top: 130px;
    white-space: nowrap;
  }
}

.item-selector {
  height: 20px;
}
