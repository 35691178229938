.header-wrapper {
  background: #FFFFFF;
  .header-content-wrapper {
    margin: auto;
    max-width: 1248px;
  }
  .user-name-wrapper {
    margin-right: 2%;
  }
  .config-button {
    width: 30px;
    height: 30px;
    padding: 0px;
    background: #FEB300;
    border-radius: 3px;
  }
  .button {
    width: 100px;
    height: 30px;
    font-family: Montserrat;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }
  .register-button {
    background: #FEB300;
    border-radius: 3px;
  }

  .register-button:active {
    background: #FEB300;
  }

  .register-button:focus {
    background: #FEB300;
  }

  .register-button:hover {
    background: #FEB300;
  }

  .logo-wrapper{
    text-align: center;
  }
  .login-button {
    margin-right: 1%;
  }
}

@media (max-width: 600px){
  .header-wrapper{
    .button {
      width: auto;
      height: auto;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
  }
}
