.ant-table{
  font-size: 0.8vw;
}

.img-holder{
  width: 5vw;
}

.filters-toggle-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-collapse-content {
  border-top: 0px;
}

.ant-list-split .ant-list-item {
  padding: 0px;
  padding-bottom: 20px;
}

.ant-layout.ant-layout-has-sider{
  .site-content {
    margin-top: 0;
  }

  .filters-sider {
    background-color: #f0f2f5;
    overflow: auto;
    left: 0;
    max-width: 190px;
    width: 100%;
  }
}

@media (max-width: 992px){
  .site-content{
    margin-left: 0px !important;
  }
}
