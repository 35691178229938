.site-content{
  font-family: Montserrat;
  font-style: normal;
  max-width: 1248px;
  width: 100%;
  margin: auto;
}

.site-content-wrapper {
  padding-left: 96px;
  padding-right: 96px;
}

@media (max-width: 1440px){
  .site-content-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
