.brand-wrapper{
  text-align: center;
}

.add-info {
  padding: 5px 0px;
  .title {
    display: block;
    padding: 0px 15px;
    font-weight: 300;
    font-size: 16px;
  }

  .text {
    padding-left: 22px;
    font-size: 16px;
    font-weight: 400;
  }
}

.more-info-content{
  .props{
    margin-bottom: 10px;
  }
}

.social{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
}

.btn-buy-wrapper{
  text-align: center;
}

@media (max-width: 600px){
  .add-info {
    .title {
      padding: 0px 0px;
    }
  }
}
