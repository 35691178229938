.error-title {
  font-size: 144px;
  font-weight: 900;
}

.error-subtitle {
  font-size: 36px;
  font-weight: 700;
}

.error-text {
  font-size: 20px;
  font-weight: 400;
}

.hompepage-button {
  background: #FEB300;
  border-radius: 3px;
  font-weight: 600;
}
