.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #F5F5F5;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.compare-wrapper {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 1248px;
  margin: auto;
}

.compare-list {
  padding-right: 5%;
}

.compare-button-wrapper {
  text-align: right;
}

.compare-button {
  width: 120px;
  height: 100%;
  background: #FEB300;
  border-radius: 3px;
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.compare-button:active {
  background: #FEB300;
}

.compare-button:focus {
  background: #FEB300;
}

.compare-button:hover {
  background: #FEB300;
}

.item-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  background-color: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 3px;
  overflow-x: auto;
  overflow-y: hidden;

  .item {
    font-size: 16px;
    background: #EDEDED;
    .text {
      height: 100%;
      white-space: nowrap;
      padding: 0px 6px;
    }
    .button{
      padding: 0;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 0;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-left: 10px;
    padding: 0 6px 0;
    height: 25px;
    border-radius: 5px;
  }
}

.search-input {
  width: 100%;
}
