.ant-modal.modal{
  background: linear-gradient(180deg, #FFF 0%, #BE7D00 100%);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 5px 5px;
  padding: 0px;
  border: 0px;
  padding-bottom: 0 !important;
  .logo-wrapper{
    height: 100px;
  }
}
