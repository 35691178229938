.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: auto;
  bottom: 5px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 22px 22px;
}

.ant-list-split .ant-list-item {
  border-bottom: 0px;
}

.ant-collapse-content-box {
  background-color: #FFFEFE;
}

.item-content {
  padding: 30px 0px;
}

.description {
  padding: 20px 0px;
}

.list-item {
  background-color: #FFFEFE;
}

@media (max-width: 600px){
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 16px 22px;
  }
}
