.footer-wrapper{
  .footer {
    font-family: Montserrat;
    font-style: normal;
    margin: auto;
    max-width: 1248px;
    .main-wrapper {
      margin-bottom: 1%;
      .text {
        display: inline-block;
        width: 34%;
        margin: 0;
      }
      @media (max-width: 600px){
        .title {
          font-size: 10px;
        }
      }
      .title {
        margin-bottom: 1%;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        white-space: nowrap;
      }
    }

    .email-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #7A7A7A;
    }

    .social-wrapper{
      max-width: 100px;
      min-width: 95px;
    }
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.copyright-wrapper {
  padding: 6px;
  .copyright-wrapper-text {
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 22px;
    color: #000000;
    opacity: 0.4;
  }
  .hspace{
    margin-bottom: 3px;
    margin-right: 5px;
  }
}

@media (max-width: 992px){
  .footer-section{
    margin-bottom: 40px !important;
  }
}
