.beer {
  .header-logo {
    background-image: url("../images/beer-header-logo.png");
    height: 60px;
    background-size: auto 30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: 775px){
    .header-logo {
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
