.switcher { color: inherit; }

.switcher:hover { color: inherit; }

.ant-btn-text[disabled], .ant-btn-text[disabled]:hover, .ant-btn-text[disabled]:focus, .ant-btn-text[disabled]:active {
  background: #C4C4C4;
  border-color: #C4C4C4;
}

.card-button {
  background-color: #FEB300;
  width: 94px;
  padding: 0;
  border-radius: 3px;
  font-family: Montserrat;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  span {
    width: 60%;
  }
}
.card-button:hover {
  background-color: #FEB300;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.25), 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.ant-btn-text:focus {
  background-color: transparent;
}

.ant-btn-text:active {
  background-color: transparent;
}
.ant-btn-text:hover {
  background-color: transparent;
}

.close-button{
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
