.travel {
  .ant-layout {
    background: #FFFFFF;
  }

  .ant-layout-sider-children {
    background: #FFFFFF;
  }

  section {
    background: #FFFFFF;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .header-wrapper {
    .register-button {
      background: #00509D;
      color: #FFFFFF;
    }
    .register-button:hover {
      background: #00509D;
      color: #FFFFFF;
    }
  }
  .header-logo {
    background-image: url("../images/travel-header-logo.png");
    height: 60px;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
