.compare-attributes-wrapper {
  width: 90%;
  margin: 0px auto;
  .card {
    text-align: center;
    box-sizing: border-box;
    border-radius: 3px;
    .img-wrapper{
      margin: auto;
    }
    .props-wrapper{
      margin: auto;
      box-sizing: border-box;
      border-radius: 3px;
      .item-rows {
        height: 80px;
        width: 100%;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          font-weight: 300;
        }
      }
    }
  }
}

.grey {
  background-color: #F9F9F9;
}
