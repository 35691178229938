.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #FFFFFF !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 0px !important;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important;
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  background-color: #feb300;
  border-color: #feb300;
}

.reset-filter-button {
  padding: 0;
  text-align: left;
}

.ant-checkbox-group-item {
  width: 100%;
}

.filters-collapse-button {
  float: right;
}

.filter-wrapper {
  padding-right: 50px;
  .filters-container {
    .title {
      position: relative;
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }
    .filter-options {
      ul {
        padding: 0;
        padding-bottom: 30px;
        margin: 0;
        list-style: none;

        li {
          padding: 0;
          margin: 25px 1px;
          list-style: none;
          .ant-checkbox-inner {
            height: 14px;
            width: 14px;
          }

          label {
            margin-bottom: 3px;
            position: relative;
            cursor: pointer;
            font-size: 14px;
          }

        }
      }
    }
  }
}

.radio-button {
  display: block;
  height: 30px;
  line-height: 30px;
}
